export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Saldo/Extrato',
        to: '/dashboard',
        icon: 'cil-money',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Dispositivos Móveis',
        to: '/mobile',
        icon: 'cil-mobile',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Conta Bancária',
        to: '/contabancaria',
        icon: 'cil-bank',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Transferência',
        to: '/tranferencia',
        icon: 'cil-transfer',
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },      
    ]
  }
]